
import {Options, Vue} from 'vue-class-component';
import {mapActions, mapMutations, mapState} from "vuex";

@Options({
  components: {},
  computed: {
    ...mapState(['activeTeamQuiz', 'user'])
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([])
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      (vm as TeamResult).load(to, from);
    });
  },

  beforeRouteUpdate(to, from, next) {
    (this as TeamResult).load(to, from);
    next();
  },
})
export default class TeamResult extends Vue {

  public activeTeamQuiz: any;
  public user: any;

  public score = 0;


  public load(to: any, from: any) {
    this.getScore();
  }

  public getScore() {
    let score = 0;
    if (this.activeTeamQuiz.questions) {
      for (const q of this.activeTeamQuiz.questions) {
        if (this.isQuestionScored(q)) {
          score += 1;
        }
      }
    }
    this.score = score;
  }

  public getQuestionScore(q: any) {
    let score = 0;
    for (const a of q.answers) {
      if (a.answer.right_answer) {
        score += 1;
      }
    }
    return score;
  }

  public isQuestionScored(q: any) {
    const score = this.getQuestionScore(q);
    if (score >= Math.floor(this.activeTeamQuiz.team.length / 2)) {
      return true;
    }
    return false;
  }

  public isQuestionRight(q: any) {
    const teamMemberId = this.getTeamMemberId();
    for (const a of q.answers) {
      if (a.team_member_id === teamMemberId) {
        return a.answer.right_answer === 1;
      }
    }
    return false;
  }

  public getTeamMemberId() {
    for (const m of this.activeTeamQuiz.team) {
      if (m.user_id === this.user.id) {
        return m.id;
      }
    }
    return null;
  }

}
