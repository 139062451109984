import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ad8f344"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-pa-md" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_form, { ref: "form" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_q_input, {
                outlined: "",
                autofocus: "",
                modelValue: _ctx.email,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                label: "E-Mail Adresse",
                "lazy-rules": "ondemand",
                rules: [
                  val => val && val.length > 0 || 'ist ein Pflichtfeld',
                  _ctx.isValidEmail
                  ],
                onKeydown: _withKeys(_withModifiers(_ctx.clickRegister, ["prevent"]), ["enter"])
              }, null, 8, ["modelValue", "rules", "onKeydown"])
            ])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_q_input, {
                outlined: "",
                modelValue: _ctx.firstName,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.firstName) = $event)),
                label: "Vorname",
                "lazy-rules": "ondemand",
                rules: [ val => val && val.length > 0 || 'ist ein Pflichtfeld'],
                onKeydown: _withKeys(_withModifiers(_ctx.clickRegister, ["prevent"]), ["enter"])
              }, null, 8, ["modelValue", "rules", "onKeydown"])
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_q_input, {
                outlined: "",
                modelValue: _ctx.lastName,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.lastName) = $event)),
                label: "Nachname",
                "lazy-rules": "ondemand",
                rules: [ val => val && val.length > 0 || 'ist ein Pflichtfeld'],
                onKeydown: _withKeys(_withModifiers(_ctx.clickRegister, ["prevent"]), ["enter"])
              }, null, 8, ["modelValue", "rules", "onKeydown"])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_q_input, {
                outlined: "",
                type: "password",
                modelValue: _ctx.password,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.password) = $event)),
                label: "Passwort",
                "lazy-rules": "ondemand",
                rules: [ val => val && val.length > 0 || 'ist ein Pflichtfeld'],
                onKeydown: _withKeys(_withModifiers(_ctx.clickRegister, ["prevent"]), ["enter"])
              }, null, 8, ["modelValue", "rules", "onKeydown"])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_q_input, {
                outlined: "",
                type: "password",
                modelValue: _ctx.passwordConfirmation,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.passwordConfirmation) = $event)),
                label: "Passwort wiederholen",
                "lazy-rules": "ondemand",
                rules: [
                  val => val && val.length > 0 || 'ist ein Pflichtfeld',
                  val => val === this.password || 'Passwörter stimmen nicht überein'
              ],
                onKeydown: _withKeys(_withModifiers(_ctx.clickRegister, ["prevent"]), ["enter"])
              }, null, 8, ["modelValue", "rules", "onKeydown"])
            ])
          ])
        ]),
        _: 1
      }, 512),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_q_btn, {
            rounded: "",
            color: "primary",
            label: "Zurück",
            icon: "arrow_back",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$router.back()))
          }),
          _createVNode(_component_q_btn, {
            rounded: "",
            color: "primary",
            label: "Zugang anlegen",
            icon: "check",
            onClick: _ctx.clickRegister
          }, null, 8, ["onClick"])
        ])
      ])
    ]),
    _: 1
  }))
}