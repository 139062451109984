import api from "@/service/api";
import {AxiosPromise} from "axios/index";

class Service {
    public login(params: any): AxiosPromise {
        return api.post('api/auth/login', params);
    }

    public register(params: any): AxiosPromise {
        return api.post('api/auth/register', params);
    }

    public loadCourses(): AxiosPromise {
        return api.post('api/quiz/loadCourses');
    }

    public createQuiz(params: any): AxiosPromise {
        return api.post('api/quiz/createQuiz', params);
    }

    public search(params: any): AxiosPromise {
        return api.post('api/quiz/search', params);
    }

    public loadQuiz(params: any): AxiosPromise {
        return api.post('api/quiz/load', params);
    }

    public likeQuestion(params: any): AxiosPromise {
        return api.post('api/quiz/like', params);
    }

    public dislikeQuestion(params: any): AxiosPromise {
        return api.post('api/quiz/dislike', params);
    }

    public commentQuestion(params: any): AxiosPromise {
        return api.post('api/quiz/comment', params);
    }

    public users(): AxiosPromise {
        return api.post('api/quiz/users');
    }

    public createChallenge(params: any): AxiosPromise {
        return api.post('api/quiz/createChallenge', params);
    }

    public challenges(): AxiosPromise {
        return api.post('api/quiz/challenges');
    }

    public myChallenges(): AxiosPromise {
        return api.post('api/quiz/myChallenges');
    }

    public saveChallenge(params: any): AxiosPromise {
        return api.post('api/quiz/saveChallenge', params);
    }

    public createTeamQuiz(params: any): AxiosPromise {
        return api.post('api/quiz/team/create', params);
    }

    public teamLoadAll(): AxiosPromise {
        return api.post('api/quiz/team/loadAll');
    }

    public teamAnswer(params: any): AxiosPromise {
        return api.post('api/quiz/team/answer', params);
    }

    public teamQuiz(params: any): AxiosPromise {
        return api.post('api/quiz/team/quiz', params);
    }

    public saveResult(params: any): AxiosPromise {
        return api.post('api/quiz/result', params);
    }

    public rate(params: any): AxiosPromise {
        return api.post('api/quiz/rate', params);
    }

    public popular(): AxiosPromise {
        return api.post('api/quiz/popular');
    }
}

const service = new Service();
export default service;
