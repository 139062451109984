import {createStore} from 'vuex'
import api from "@/service/api";
import {AxiosResponse} from "axios/index";
import VuexPersistence from "vuex-persist";
import service from "@/service";
import {Props} from "@/components/QuestionDialog.vue";

export default createStore({
    state: {
        auth: false,
        token: '',
        user: null,
        courses: [],
        users: [],
        queryResult: [],
        newQuiz: {
            id: -1,
            course: {
                shortcut: '',
                name: ''
            },
            name: '',
            questions: []
        },
        quiz: {},
        questionCount: 0,
        challenges:[],
        myChallenges: [],
        activeChallenge: {},
        teamQuiz: [],
        activeTeamQuiz: {},
        popular: [],
    },
    mutations: {
        changeToken(state: any, token: string) {
            state.token = token;
        },
        changeAuth(state: any, auth: boolean) {
            state.auth = auth;
        },
        changeUser(state: any, user: any) {
            state.user = user;
        },
        changeQuestions(state: any, questions: any) {
            state.newQuiz.questions = questions;
        },
        changeCourses(state: any, courses: any) {
            state.courses = courses;
        },
        resetNewQuiz(state: any) {
            state.newQuiz = {
                id: -1,
                course: {
                    shortcut: '',
                    name: ''
                },
                name: '',
                questions: []
            };
        },
        changeNewQuiz(state: any, newQuiz: any) {
            state.newQuiz = newQuiz;
        },
        changeQueryResult(state: any, queryResult: any) {
            state.queryResult = queryResult;
        },
        resetQueryResult(state: any) {
            state.queryResult = [];
        },
        changeQuiz(state: any, quiz: any) {
            state.quiz = quiz;
        },
        changeQuestionCount(state: any, questionCount: any) {
            state.questionCount = questionCount;
        },
        changeUsers(state: any, users: any) {
            state.users = users;
        },
        changeChallenges(state: any, challenges: any) {
            state.challenges = challenges;
        },
        changeMyChallenges(state: any, myChallenges: any) {
            state.myChallenges = myChallenges;
        },
        changeActiveChallenge(state: any, challenge: any) {
            state.activeChallenge = challenge;
        },
        changeTeamQuiz(state: any, teamQuiz: any) {
            state.teamQuiz = teamQuiz;
        },
        changeActiveTeamQuiz(state: any, activeTeamQuiz: any) {
            state.activeTeamQuiz = activeTeamQuiz;
        },
        changePopular(state: any, popular: any) {
            state.popular = popular;
        },
    },
    actions: {
        login({dispatch, commit, state, rootGetters}, params) {
            return service.login(params).then((response: AxiosResponse) => {
                if (response && response.data && response.data.access_token) {
                    commit('changeToken', response.data.access_token);
                    commit('changeUser', response.data.user);
                    commit('changeAuth', true);
                } else {
                    dispatch('logout');
                }
            })
        },

        logout({dispatch, commit, state, rootGetters}) {
            commit('changeToken', '');
            commit('changeAuth', false);
            commit('changeUser', null);

        },

        addQuestion({dispatch, commit, state, rootGetters}, params) {
            let newId = -1;
            for (const q of state.newQuiz.questions) {
                if (q.id <= newId) {
                    newId = q.id - 1;
                }
            }
            params.id = newId;
            state.newQuiz.questions.push(params);
            commit('changeQuestions', state.newQuiz.questions);
        },

        updateQuestion({dispatch, commit, state, rootGetters}, params) {
            for (const q of state.newQuiz.questions) {
                if (q.id === params.id) {
                    q.question = params.data.question;
                    q.answers = params.data.answers;
                }
            }
            commit('changeQuestions', state.newQuiz.questions);
        },

        deleteQuestion({dispatch, commit, state, rootGetters}, params) {
            let index = 0;
            for (const q of state.newQuiz.questions) {
                if (q.id === params.id) {
                    break;
                }
                index++;
            }
            state.newQuiz.questions.splice(index, 1);
            commit('changeQuestions', state.newQuiz.questions);
        },

        loadAppData({dispatch, commit, state, rootGetters}) {
            const p: any[] = [];

            p.push(dispatch('loadCourses'));
            p.push(dispatch('loadUsers'));

            return Promise.all(p);
        },

        loadCourses({dispatch, commit, state, rootGetters}) {
            return service.loadCourses().then((response) => {
                commit('changeCourses', response.data);
            });
        },

        loadUsers({dispatch, commit, state, rootGetters}) {
            return service.users().then((response) => {
                commit('changeUsers', response.data);
            });
        },

        createQuiz({dispatch, commit, state, rootGetters}, params: any) {
            return service.createQuiz(params);
        },

        search({dispatch, commit, state, rootGetters}, params: any) {
            commit('changeQueryResult', []);
            return service.search(params).then((response) => {
                commit('changeQueryResult', response.data);
            });
        },

        startQuiz({dispatch, commit, state, rootGetters}, params) {
            commit('changeQuiz', {});
            commit('changeQuestionCount', params.questionCount);
            return service.loadQuiz({id: params.id}).then((response) => {
                commit('changeQuiz', response.data);
            });
        },

        loadEditQuiz({dispatch, commit, state, rootGetters}, params) {
            commit('resetNewQuiz');
            return service.loadQuiz({id: params.id}).then((response) => {
                commit('changeNewQuiz', response.data);
            });
        },

        loadHome({dispatch, commit, state, rootGetters}) {
            const p: any[] = [];

            p.push(dispatch('loadChallenges'));
            p.push(dispatch('loadMyChallenges'));
            p.push(dispatch('loadAllTeamQuiz'));
            p.push(dispatch('loadPopular'));

            return Promise.all(p);
        },

        loadChallenges({dispatch, commit, state, rootGetters}) {
            commit('changeChallenges', []);
            return service.challenges().then((response) => {
                commit('changeChallenges', response.data);
            });
        },

        loadMyChallenges({dispatch, commit, state, rootGetters}) {
            commit('changeMyChallenges', []);
            return service.myChallenges().then((response) => {
                commit('changeMyChallenges', response.data);
            });
        },
        saveChallenge({dispatch, commit, state, rootGetters}, params) {
            return service.saveChallenge(params).then(() => {
                dispatch('loadHome');
            });
        },
        loadAllTeamQuiz({dispatch, commit, state, rootGetters}) {
            commit('changeTeamQuiz', []);
            return service.teamLoadAll().then((response) => {
                commit('changeTeamQuiz', response.data);
            });
        },
        loadTeamQuiz({dispatch, commit, state, rootGetters}, params) {
            return service.teamQuiz(params).then((response) => {
                commit('changeActiveTeamQuiz', response.data);
            });
        },
        createTeamQuiz({dispatch, commit, state, rootGetters}, params: any){
            return service.createTeamQuiz(params).then((response) => {
                commit('changeActiveTeamQuiz', response.data);
            });
        },
        loadPopular({dispatch, commit, state, rootGetters}){
            return service.popular().then((response) => {
                commit('changePopular', response.data);
            });
        },
    },
    modules: {},
    plugins: [new VuexPersistence().plugin]
})
