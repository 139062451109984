export default class Copier {

    public static deepCopy(obj: any, copyNullAsEmptyString = false): any {

        /*eslint-disable */
        let copy;

        if (obj === null && copyNullAsEmptyString) {
            return '';
        }

        // Handle the 3 simple types, and null or undefined
        if (null === obj || "object" !== typeof obj) {
            return obj;
        }

        // Handle Date
        if (obj instanceof Date) {
            copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }

        // Handle Array
        if (obj instanceof Array) {
            copy = [];
            for (let i = 0, len = obj.length; i < len; i++) {
                copy[i] = Copier.deepCopy(obj[i], copyNullAsEmptyString);
            }
            return copy;
        }

        // Handle Object
        if (obj instanceof Object) {
            copy = {};
            for (const attr in obj) {
                if (obj.hasOwnProperty(attr)) {
                    (copy as any)[attr] = Copier.deepCopy(obj[attr], copyNullAsEmptyString);
                }
            }
            return copy;
        }
        /*eslint-enable */

        throw new Error("Unable to copy obj! Its type isn't supported.");
    }

}
