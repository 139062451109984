
import './styles/quasar.scss'
import langDe from 'quasar/lang/de'
import '@quasar/extras/material-icons/material-icons.css'

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: ['Loading'],
  lang: langDe
}
