
import {Options, prop, Vue} from 'vue-class-component';

export class Props {
  show = prop<boolean>({required: true});
  value = prop<any>({required: true});
}

@Options({
  components: {},
  computed: {},
  methods: {},
  watch: {
    show: function () {
      (this as QuestionDialog).showWatcher();
    }
  }
})
export default class QuestionDialog extends Vue.with(Props) {

  public rightAnswer = 0;
  public question = '';
  public answer1 = '';
  public answer2 = '';
  public answer3 = '';
  public answer4 = '';

  public validate = false;

  public init() {

    this.rightAnswer = 0;
    this.question = '';
    this.answer1 = '';
    this.answer2 = '';
    this.answer3 = '';
    this.answer4 = '';

    if (this.value.question) {
      this.question = this.value.question;
    }

    if (this.value.answers) {

      let i = 0;
      for (let a of this.value.answers) {
        (this as any)['answer' + (i + 1)] = a.answer;
        if (a.right_answer) {
          this.rightAnswer = i;
        }
        if (i == 3) {
          break;
        }
        i++;
      }

    }
  }

  public hide() {
    this.$emit('show-update', false);
  }

  public fireCancel() {
    this.$emit('cancel');
    this.hide();
  }

  public fireSave() {
    this.validate = true;

    if (this.question && this.answer1 && this.answer2 && this.answer3 && this.answer4) {
      this.$emit('save', {
        question: this.question,
        answers: [
          {
            answer: this.answer1,
            right_answer: this.rightAnswer === 0 ? 1 : 0
          },
          {
            answer: this.answer2,
            right_answer: this.rightAnswer === 1 ? 1 : 0
          },
          {
            answer: this.answer3,
            right_answer: this.rightAnswer === 2 ? 1 : 0
          },
          {
            answer: this.answer4,
            right_answer: this.rightAnswer === 3 ? 1 : 0
          }
        ]
      });
      this.hide();
    }
  }

  public showWatcher() {
    if (this.show) {
      this.init();
    }
  }

  public nl2br(text: string) {
    var breakTag = '<br />';
    var replaceStr = '$1' + breakTag + '$2';
    return (text + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
  }

}
