
import {Options, Vue} from 'vue-class-component';
import {mapActions, mapMutations, mapState} from "vuex";
import QuestionDialog from "@/components/QuestionDialog.vue";
import Loading from 'quasar/src/plugins/Loading.js';import useQuasar from 'quasar/src/composables/use-quasar.js';;
import Home from "@/views/Home.vue";
import Copier from "@/util/copier/Copier";
import service from "@/service";

@Options({
  components: {},
  computed: {
    ...mapState(['activeTeamQuiz', 'users', 'user'])
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([])
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      (vm as TeamQuiz).load(to, from);
    });
  },

  beforeRouteUpdate(to, from, next) {
    (this as TeamQuiz).load(to, from);
    next();
  },
})
export default class TeamQuiz extends Vue {

  public questionCount: any;
  public users: any;
  public activeTeamQuiz: any;
  public user: any;

  public questions: any = [];
  public currentQuestion = 0;
  public showResult = false;
  public showFinal = false;
  public showQuestion = true;
  public activeAnswer = null;
  public answers: any = [];
  public score = 0;

  public comment = '';
  public like = false;
  public dislike = false;

  public quizRating = 0;


  public load(to: any, from: any) {
    this.initQuestions();
    this.getAnswers();
    this.quizRating = 0;
    if (this.activeTeamQuiz.quiz.ratings && this.activeTeamQuiz.quiz.ratings.length) {
      for (const r of this.activeTeamQuiz.quiz.ratings) {
        if (r.user_id === this.user.id) {
          this.quizRating = r.rating;
          break;
        }
      }
    }
  }

  public initQuestions() {
    this.questions = [];
    const teamMemberId = this.getTeamMemberId();
    for (const q of this.activeTeamQuiz.questions) {
      let add = true;
      for (const a of q.answers) {
        if (a.team_member_id === teamMemberId) {
          add = false;
          break;
        }
      }
      if (add) {
        this.questions.push(q.question);
      }
    }

    this.questionCount = this.questions.length;
  }

  public clickNext() {
    if (this.showResult) {

      if (this.comment) {
        service.commentQuestion({
          id: this.questions[this.currentQuestion].id,
          comment: this.comment
        });
      }

      if (this.like) {
        service.likeQuestion({
          id: this.questions[this.currentQuestion].id
        });
      }

      if (this.dislike) {
        service.dislikeQuestion({
          id: this.questions[this.currentQuestion].id
        });
      }

      this.showResult = false;
      if ((this.currentQuestion + 1) === this.questionCount) {
        this.showFinal = true;
        this.showQuestion = false;
      } else {
        this.currentQuestion += 1;
        this.getAnswers();
        this.activeAnswer = null;
        this.showQuestion = true;
      }
    } else {

      service.teamAnswer({
        'question_answer_id': this.activeAnswer,
        'team_quiz_question_id': this.getTeamQuestionId(this.questions[this.currentQuestion].id),
        'team_member_id': this.getTeamMemberId()
      });
      this.comment = '';
      this.dislike = false;
      this.like = false;
      this.showResult = true;
      this.showQuestion = false;
    }
  }

  public getTeamMemberId() {
    for (const m of this.activeTeamQuiz.team) {
      if (m.user_id === this.user.id) {
        return m.id;
      }
    }
    return null;
  }

  public getTeamQuestionId(questionId: any) {
    for (const q of this.activeTeamQuiz.questions) {
      if (q.question_id === questionId) {
        return q.id;
      }
    }
    return null;
  }

  public getProcess() {
    if (this.showFinal) {
      return 1;
    } else {
      return this.currentQuestion / this.questionCount;
    }
  }

  public getQuestion() {
    if (this.questions && this.questions[this.currentQuestion]) {
      return this.questions[this.currentQuestion].question;
    }
    return '';
  }

  public getAnswers() {
    if (this.questions && this.questions[this.currentQuestion] && this.questions[this.currentQuestion].answers) {
      this.answers = this.questions[this.currentQuestion].answers.sort(() => 0.5 - Math.random());
    }
  }

  public clickAnswer(a: any) {
    this.activeAnswer = a.id;
  }

  public getAnswerColor(a: any) {
    if (a.id === this.activeAnswer) {
      return 'primary';
    }
    return '';
  }

  public getRightAnswer() {
    for (const a of this.questions[this.currentQuestion].answers) {
      if (a.right_answer) {
        return a.id;
      }
    }
    return 0;
  }

  public getAnswerColorResult(a: any) {
    if (a.id === this.getRightAnswer()) {
      return 'green';
    }
    if (a.id === this.activeAnswer) {
      return 'red';
    }
    return '';
  }

  public getAnswerTextColor(a: any) {
    if (a.id === this.activeAnswer) {
      return 'white';
    }
    return 'black';
  }

  public getAnswerTextColorResult(a: any) {
    if (this.getAnswerColorResult(a) !== '') {
      return 'white';
    }
    return 'black';
  }

  public nextButtonDisabled() {
    if (this.showQuestion && this.activeAnswer === null) {
      return true;
    }
    return false;
  }

  public clickLike() {
    this.like = !this.like;
    this.dislike = false;
  }

  public clickDislike() {
    this.dislike = !this.dislike;
    this.like = false;
  }

  public getQuestionLikes() {
    if (this.questions && this.questions[this.currentQuestion]) {
      return this.questions[this.currentQuestion].rating_pos;
    }
    return 0;
  }

  public getQuestionDislikes() {
    if (this.questions && this.questions[this.currentQuestion]) {
      return this.questions[this.currentQuestion].rating_neg;
    }
    return 0;
  }

  public saveRating() {
    service.rate({quiz_id: this.activeTeamQuiz.quiz.id, rating: this.quizRating});
  }


}
