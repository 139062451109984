
import {Options, Vue} from 'vue-class-component';
import {mapActions, mapMutations, mapState} from "vuex";
import QuestionDialog from "@/components/QuestionDialog.vue";
import Loading from 'quasar/src/plugins/Loading.js';import useQuasar from 'quasar/src/composables/use-quasar.js';;
import Home from "@/views/Home.vue";

@Options({
  components: {QuestionDialog},
  computed: {
    ...mapState(['newQuiz', 'courses'])
  },
  methods: {
    ...mapActions(['addQuestion', 'updateQuestion', 'deleteQuestion', 'createQuiz']),
    ...mapMutations(['resetNewQuiz'])
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      (vm as NewQuiz).load(to, from);
    });
  },

  beforeRouteUpdate(to, from, next) {
    (this as NewQuiz).load(to, from);
    next();
  },
})
export default class NewQuiz extends Vue {

  public newQuiz: any;
  public addQuestion: any;
  public updateQuestion: any;
  public deleteQuestion: any;
  public courses: any;
  public createQuiz: any;
  public resetNewQuiz: any;

  public showNewQuestion = false;
  public showEditQuestion = false;
  public showDeleteDialog = false;

  public editQuestion: any = {};
  public questionToDelete: any = {};

  public load(to: any , from: any) {
    this.resetNewQuiz();
  }

  public onAddQuestion(newData: any) {
    this.addQuestion(newData);
  }

  public onUpdateQuestion(newData: any) {
    this.updateQuestion({
      id: this.editQuestion.id,
      data: newData
    });
  }

  public onShowDeleteDialog(q: any) {
    this.questionToDelete = q;
    this.showDeleteDialog = true;
  }

  public onDeleteQuestion() {
    this.deleteQuestion({id: this.questionToDelete.id});
  }

  public startEditQuestion(q: any) {
    this.editQuestion = q;
    this.showEditQuestion = true;
  }

  public checkCourse() {
    for (let c of this.courses) {
      if (c.shortcut === this.newQuiz.course.shortcut) {
        this.newQuiz.course.name = c.name;
        break;
      }
    }
  }

  public clickSave() {
    Loading.show( {
      message: 'Speichern wird durchgeführt ...'
    });

    this.createQuiz(this.newQuiz).then(() => {
      Loading.hide();
      this.$router.push('/');
      this.resetNewQuiz();
    }).catch(() => {
      Loading.hide();
    });
  }


}
