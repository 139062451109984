
import {Options, Vue} from 'vue-class-component';
import service from "@/service";

@Options({
  components: {},
})
export default class Register extends Vue {

  public email = '';
  public firstName = '';
  public lastName = '';
  public password = '';
  public passwordConfirmation = '';

  public isValidEmail () {
    const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
    return emailPattern.test(this.email) || 'Keine gültige E-Mail Adresse';
  }

  public clickRegister() {
    (this.$refs['form'] as any).validate().then((success: boolean) => {
          if (success) {
            service.register({
              email: this.email,
              first_name: this.firstName,
              last_name: this.lastName,
              password: this.password,
              password_confirmation: this.passwordConfirmation
            }).then((response) => {
              if (response && response.data.user) {
                this.$router.replace('/login');
              }
            });
          }
        }
    );

  }

}
