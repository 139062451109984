
import { Options, Vue } from 'vue-class-component';
import {mapActions, mapState} from "vuex";

@Options({
  components: {
  },
  computed: {
    ...mapState(['auth'])
  },
  methods: {
    ...mapActions(['login'])
  },
})
export default class Login extends Vue {

  public login: any;
  public auth: any;

  public email = '';
  public password = '';
  public loadingLogin = false;
  public showError = false;

  public clickLogin(): void {
    (this.$refs['form'] as any).validate().then((success: boolean) => {
      if (success) {
        this.loadingLogin = true;
        this.login({email: this.email, password: this.password}).then(() => {
          this.loadingLogin = false;
          this.showError = false;
          if (this.auth) {
            this.$router.replace('/');
          }
        }).catch(() => {
          this.loadingLogin = false;
          this.showError = true;
        });
      }
    });

  }

}
