import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Register from "@/views/Register.vue";
import store from "@/store";
import NewQuiz from "@/views/quiz/NewQuiz.vue";
import EditQuiz from "@/views/quiz/EditQuiz.vue";
import SoloQuiz from "@/views/quiz/SoloQuiz.vue";
import ChallengeQuiz from "@/views/quiz/ChallengeQuiz.vue";
import TeamQuiz from "@/views/quiz/TeamQuiz.vue";
import TeamResult from "@/views/quiz/TeamResult.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      noAuth: true
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      noAuth: true
    },
  },
  {
    path: '/quiz/new',
    name: 'NewQuiz',
    component: NewQuiz
  },
  {
    path: '/quiz/:id',
    name: 'EditQuiz',
    component: EditQuiz
  },
  {
    path: '/quiz/solo',
    name: 'SoloQuiz',
    component: SoloQuiz
  },
  {
    path: '/quiz/challenge',
    name: 'ChallengeQuiz',
    component: ChallengeQuiz
  },
  {
    path: '/quiz/team',
    name: 'TeamQuiz',
    component: TeamQuiz
  },
  {
    path: '/quiz/team/result',
    name: 'TeamResult',
    component: TeamResult
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach( (to, from, next) => {
  // ...
  // explicitly return false to cancel the navigation
  if (to.meta.noAuth) {
    next();
    return;
  }
  if (!store.state.auth) {
    next('/login');
    return;
  }
  next();
})

export default router
