import store from "@/store";
import axios from "axios/index";
import router from "@/router";


export const base = process.env.VUE_APP_API_BASE_URL || "";
//TODO: baseURL in enviroment auslagern!
const api = axios.create({
    baseURL: base
});

api.interceptors.request.use((config: any) => {
    const token = store.state.token;

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

api.interceptors.response.use((response) => response, (error) => {
    // whatever you want to do with the error
    if (error.response.status === 401) {
        store.dispatch('logout').then(() => {
            router.push('/login');
        })
    }
});

export default api;
