
import {Options, Vue} from 'vue-class-component';
import {mapActions, mapMutations, mapState} from "vuex";
import Loading from 'quasar/src/plugins/Loading.js';;
import Copier from "@/util/copier/Copier";

@Options({
  components: {},
  computed: {
    ...mapState(['user', 'courses', 'queryResult', 'challenges', 'myChallenges', 'users', 'teamQuiz', 'popular']),
  },
  methods: {
    ...mapActions(['loadAppData', 'search', 'startQuiz', 'loadHome', 'saveChallenge', 'loadTeamQuiz', 'createTeamQuiz']),
    ...mapMutations(['resetQueryResult', 'changeActiveChallenge', 'changeActiveTeamQuiz']),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      (vm as Home).load(to, from);
    });
  },

  beforeRouteUpdate(to, from, next) {
    (this as Home).load(to, from);
    next();
  }
})
export default class Home extends Vue {

  public search: any;
  public loadAppData: any;
  public queryResult: any;
  public resetQueryResult: any;
  public startQuiz: any;
  public loadHome: any;
  public challenges: any;
  public myChallenges: any;
  public users: any;
  public changeActiveChallenge: any;
  public saveChallenge: any;
  public teamQuiz: any;
  public loadTeamQuiz: any;
  public user: any;
  public createTeamQuiz: any;
  public popular: any;

  public searchText = '';
  public query = '';
  public loadingSearch = false;
  public startDialog = false;
  public startQuizObject: any = {};
  public loadingStartQuiz = false;
  public quizMode = 'solo';
  public quizQuestionCount = 1;
  public team: any[] = [];
  public teamUser = null;

  public load(to: any, from: any) {
    this.loadAppData();
    this.resetQueryResult();
    Loading.show();
    this.loadHome().then(() => {
      Loading.hide();
    }).catch(() => {
      Loading.hide();
    });

  }

  public clickSearch() {
    this.query = this.searchText;
    if (this.query) {
      this.loadingSearch = true;
      this.search({
        query: this.query
      }).then(() => {
        this.loadingSearch = false;
      });
    } else {
      this.resetQueryResult();
    }
  }

  public clearQuery() {
    this.query = '';
    this.searchText = '';
    this.resetQueryResult();
  }

  public clickStart(q: any) {
    this.startQuizObject = q;
    this.quizQuestionCount = Math.ceil(this.startQuizObject.questions.length / 2)
    this.startDialog = true;
  }

  public clickStartStartQuiz() {
    if (this.quizMode === 'solo') {
      this.loadingStartQuiz = true;
      this.startQuiz({id: this.startQuizObject.id, questionCount: this.quizQuestionCount}).then(() => {
        this.loadingStartQuiz = false;
        this.$router.push('/quiz/solo');
      });
    } else if (this.quizMode === 'team') {
      if (this.team.length) {
        this.loadingStartQuiz = true;
        const team = Copier.deepCopy(this.team);
        team.push(this.user.id);
        this.createTeamQuiz({
          'quiz_id': this.startQuizObject.id,
          'question_count': this.quizQuestionCount,
          'team': team
        }).then(() => {
          this.loadingStartQuiz = false;
          this.$router.push('/quiz/team');
        });
      }
    }
  }

  public clickStartChallengeQuiz(c: any) {
    Loading.show();
    this.changeActiveChallenge(c);
    this.startQuiz({id: c.quiz.id, questionCount: c.questions}).then(() => {
      Loading.hide();
      this.$router.push('/quiz/challenge');
    }).catch(() => {
      Loading.hide();
    });
  }

  public clickStartTeamQuiz(c: any) {
    Loading.show();
    this.loadTeamQuiz({id: c.id}).then(() => {
      Loading.hide();
      this.$router.push('/quiz/team');
    }).catch(() => {
      Loading.hide();
    });
  }

  public clickShowTeamResult(c: any) {
    Loading.show();
    this.loadTeamQuiz({id: c.id}).then(() => {
      Loading.hide();
      this.$router.push('/quiz/team/result');
    }).catch(() => {
      Loading.hide();
    });
  }

  public clickEditQuiz(q: any) {
    this.$router.push('/quiz/' + q.id);
  }

  public getLikes(quiz: any) {
    let likes = 0;
    for (const q of quiz.questions) {
      likes += q.rating_pos;
    }
    return likes;
  }

  public getDislikes(quiz: any) {
    let dislikes = 0;
    for (const q of quiz.questions) {
      dislikes += q.rating_neg;
    }
    return dislikes;
  }

  public getUser(userId: any) {
    for (const u of this.users) {
      if (u.id === userId) {
        return u;
      }
    }
    return {};
  }

  public hideChallenge1(c: any) {
    this.saveChallenge({
      id: c.id,
      score_2: c.score_2,
      hide_1: 1,
      hide_2: c.hide_2
    });
  }

  public hideChallenge2(c: any) {
    this.saveChallenge({
      id: c.id,
      score_2: c.score_2,
      hide_1: c.hide_1,
      hide_2: 1
    });
  }

  public getTeamUserOptions() {
    const users: any[] = [];
    for (const u of this.users) {
      if (this.team.indexOf(u.id) === -1) {
        users.push(u);
      }
    }
    return users;
  }

  public addUserToTeam() {
    this.team.push(this.teamUser);
    this.teamUser = null;
  }

  public deleteTeamUser(u: any) {
    this.team.splice(this.team.indexOf(u), 1);
  }

  public teamQuizFinished(quiz: any) {
    const teamLength = quiz.team.length;
    let finished = true;
    for (const q of quiz.questions) {
      if (q.answers) {
        if (q.answers.length !== teamLength) {
          finished = false;
          break;
        }
      } else {
        finished = false;
        break;
      }

    }
    return finished;
  }

  public teamQuizAnswered(quiz: any) {
    const teamMemberId = this.getTeamMemberId(quiz);
    let answered = true;
    for (const q of quiz.questions) {
      let qAnswered = false;
      if (q.answers) {
        for (const a of q.answers) {
          if (a.team_member_id === teamMemberId) {
            qAnswered = true;
            break;
          }
        }
      }

      if (!qAnswered) {
        answered = false;
        break;
      }
    }
    return answered;
  }

  public getTeamMemberId(q: any) {
    for (const m of q.team) {
      if (m.user_id === this.user.id) {
        return m.id;
      }
    }
    return null;
  }

  public getQuizRating(quiz:any) {
    if (quiz.ratings && quiz.ratings.length) {
      let ratingSum = 0;
      for (const r of quiz.ratings) {
        ratingSum += r.rating;
      }
      return Math.round ((ratingSum / quiz.ratings.length) * 10 ) / 10;
    }

    return 0;
  }

}
