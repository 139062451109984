<template>
  <q-layout view="lHh Lpr lFf">
    <q-header elevated >
      <q-toolbar>
        <q-btn
          flat
          dense
          round
          @click="toggleLeftDrawer"
          aria-label="Menu"
          icon="menu"
          v-if="auth"
        />

          <img src="@/assets/iu-logo.png" width="100" />

        <q-toolbar-title>
          Quiz
        </q-toolbar-title>

      </q-toolbar>
    </q-header>

    <q-drawer
      v-model="leftDrawerOpen"
      show-if-above
      bordered
      class="bg-grey-2"
      v-if="auth"
    >
      <q-list>
        <q-item-label header>Menü</q-item-label>
        <q-item clickable to="/">
          <q-item-section avatar>
            <q-icon name="home" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Home</q-item-label>
            <q-item-label caption>Startseite anzeigen</q-item-label>
          </q-item-section>
        </q-item>
        <q-item to="/quiz/new">
          <q-item-section avatar>
            <q-icon name="post_add" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Neues Quiz</q-item-label>
            <q-item-label caption>Erstelle ein neues Quiz</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable @click="clickLogout">
          <q-item-section avatar>
            <q-icon name="logout" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Abmelden</q-item-label>
            <q-item-label caption>Benutzer vom System abmelden</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <q-page-container>
      <router-view />
    </q-page-container>

  </q-layout>
</template>

<script>
import { ref } from 'vue'
import {mapActions, mapState} from "vuex";
import store from "@/store";
import router from "@/router";

export default {
  name: 'LayoutDefault',

  components: {
  },

  computed: {
    ...mapState(['auth', 'user'])
  },

  setup () {
    const leftDrawerOpen = ref(false)

    return {
      leftDrawerOpen,
      toggleLeftDrawer () {
        leftDrawerOpen.value = !leftDrawerOpen.value
      },
      clickLogout() {
        store.dispatch('logout').then(() => {
          router.replace('/login');
        })
      }
    }
  }
}
</script>

<style lang="scss">

.app-name {
  color: green;
  font-weight: bold;
}


</style>
